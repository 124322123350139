@import '../partials';

.text-callout {
  @include section-padding;
  @include top-border;
  background: $denim2;

  &__boundary {
    @include content-contaner;
  }
}